<template>
  <el-card style="min-height: 45rem">

  <el-row :gutter="20">
      <el-col :span="12">
        <el-form v-loading="loading" :model="form" label-width="9rem" style="padding: 2rem;">
          <el-form-item label="公众号AppId">
            <el-input v-model="form.app_id"></el-input>
            <div class="y-desc">公众号appid，要求与小程序有绑定且同主体</div>
          </el-form-item>
          <el-form-item label="公众号AppSecret">
            <el-input v-model="form.app_secret"></el-input>
          </el-form-item>
          <el-divider></el-divider>
          <el-form-item label="统一服务消息模版">
            <el-input v-model="form.uniform_message_tpl_id"></el-input>
            <div class="y-desc">即该公众号下模版消息id</div>
            <div class="y-desc">编号：OPENTM407908801</div>
            <div class="y-desc">标题：咨询完成通知</div>
            <div class="y-desc">一级行业：IT科技	二级行业</div>
            <div class="y-desc">二级行业：互联网|电子商务</div>
          </el-form-item>
          <el-form-item >
            <el-button @click="submit" type="primary">保存</el-button>
          </el-form-item>
        </el-form>
      </el-col>
    </el-row>
  </el-card>
</template>

<script>
export default {
  name: "wxOfficial",
  data(){
    return{
      form:{
        app_id:"",
        app_secret:"",
        uniform_message_tpl_id:"",
      },
      loading:false,
    }
  },
  mounted() {
    this.load();
  },
  methods:{
    load(){
      this.loading = true;
      this.$u.api.system.wechatOfficial().then(res=>{
        this.form = res;
        this.loading = false;
      })
    },
    submit(){
      this.$u.api.system.wechatOfficialEdit(this.form).then(()=>{
        this.$message.success("操作成功");
        this.load();
      })
    }
  }
}
</script>

<style scoped>
.y-desc{
  line-height: 1rem
}
</style>